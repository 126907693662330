'use client';

import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
import { useEffect } from 'react';
export default function GlobalError(props: {
  error: unknown;
}) {
  useEffect(() => {
    Sentry.captureException(props.error);
  }, [props.error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <Error statusCode={500} title="Error" data-sentry-element="Error" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}